@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 1);
}

.btn {
  border-radius: 9999px;
  background-color: rgba(97, 0, 148, 1);
  padding: 12px 12px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.btn:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.btn-secondary {
  background-color: rgba(21, 0, 80, 1);
}

.summary {
  background: linear-gradient(
    135deg,
    rgb(63, 0, 113) 9.86%,
    rgb(97, 0, 148) 87.79%
  );
}

.tab-title {
  transition-duration: 0.15s;
  transition-property: font-weight;
  transition-timing-function: ease-in-out;
}
